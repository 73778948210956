import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/(pre-auth)/2fa/enter-code": [20,[2]],
		"/(pre-auth)/2fa/request-code": [21,[2]],
		"/(pre-auth)/3fa": [22,[2]],
		"/(pre-auth)/language": [~23,[2]],
		"/(app)/lesson/answer": [4],
		"/(app)/lesson/content/pdf": [~5],
		"/(app)/lesson/content/slider": [~6],
		"/(app)/lesson/content/video": [~7],
		"/(app)/lesson/question": [~8],
		"/(app)/lesson/summary": [9],
		"/(app)/my-lessons": [10],
		"/(app)/personal-data/gender": [11],
		"/(app)/personal-data/pants": [12],
		"/(app)/personal-data/shirt": [13],
		"/(app)/personal-data/shoes": [14],
		"/(app)/personal-data/summary": [15],
		"/(app)/personal-data/vaccination": [16],
		"/(pre-auth)/privacy": [~24,[2]],
		"/(app)/summary": [~17],
		"/test/content-bottom": [27],
		"/test/content-top": [28],
		"/test/icon-button": [29],
		"/test/icons": [30],
		"/test/text-input": [31],
		"/(app)/thank-you": [18],
		"/(pre-auth)/visitor/[clientId]/[selfServiceConfigId]": [~25,[2]],
		"/(pre-auth)/visitor/[clientId]/[selfServiceConfigId]/sign-up": [26,[2]],
		"/(app)/welcome": [~19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';